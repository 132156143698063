.font_h1 {
    font-size: 2rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: lighter;
}

.font_h2 {
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
}

.font_h3 {
    font-size: 1.17rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h4 {
    font-size: 1.25rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h5 {
    font-size: 0.83rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h6 {
    font-size: 0.625rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_body {
    font-size: 1.6rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_subtitle {
    font-size: 1.4rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_button {
    font-size: 0.75rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_caption {
    font-size: 1.2rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_overline {
    font-size: 1rem;
    font-family: 'Noto Sans', sans-serif;
}

.color_primary {
    background: #7c2004;
    color: #fff;
}

.color_secondary {
    background: linear-gradient(to top,#7c2004, #f7931e);
    color: #fff;
}

.color_button {
    background: linear-gradient(to top,#7c2004, #f7931e);
    color: #fff;
}

.color_title {
    background: transparent;
    color: #000000;
}

.page_color {
    background: #000;
    color: #fff;
}

.color_active {
    background: transparent;
    color: #7c2004;
}

.contact_front_d {
    background: linear-gradient(to right,#f59c00, #fbcc84);
    color: #7c2004;
    border-radius: 5px 5px 5px 5px;
}

.contact_back_d .list-group {
    /* border: 1px solid #7c2004; */
}

.contact_back_d .contact_back_d_header {
    background: linear-gradient(to right,#f59c00, #fbcc84);
    color: #7c2004;
    border-radius: 5px 0 0 0;
}

.contact_back_d .contact_back_d_item {
    background: #fff;
    color: #000;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.contact_back_d .contact_back_d_item .contact_back_content span{
    color: #808080;
}

.contact_back_d.card>.list-group:last-child{
    border-radius: 0 0 0 5px;
}

.announcement_body {
    color: #000;
}

.announcement_header{
    /* border-bottom: 1px solid #7c2004; */
    border-bottom: 0;
    background: #fff5e0;
  }

.announcement_header img {
    /* filter: drop-shadow(1px 2px 0px black); */
}

.announcement_header_title {
    color: #fff;
}

.announcement_btnClose {
    background: #f7931e;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.header_topBar {
    background: #fff5e0;
    color: #000;
    filter: drop-shadow(0 2px 3px rgb(0,0,0,.1));
    /* box-shadow: 1px 0px 3px 0px rgba(255, 255, 255, .3); */
    /* border-bottom: 1px solid #333333; */
}

.header_menu {
    color: #666666;
    background: linear-gradient(to top,#f59c00, #fbcc84);
    /* box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 50%); */
}

.header_menu .header_menu_page .header_menu_content.active {
    background: transparent;
    color: #7c2004;
    /* border-bottom: 3px solid #7c2004; */
}

.banking_dropdown a li,
.claim_dropdown li {
    background: transparent;
    color: #666666;
}

.banking_dropdown a.active li,
.claim_dropdown a.active li {
    background: transparent;
    color: #7c2004;
}

.header_btnLogin {
    background: #FF5500;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}

.header_btnRegister {
    background: #7c2004;
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnLogout {
    background: red;
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnRestore {
    background: #7c2004;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnBanking {
    background: #f7931e;
    color: #fff;
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

.header_btnPromoClaim {
    background: #7c2004;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #7c2004
}

.header_topBar+.header_banking_info {
    background: #fff;
    color: #000;
    box-shadow: 4px 0px 5px 0px rgb(0 0 0 / 30%);
}

.header_topBar+.header_banking_info .header_btnRestore {
    background: #f7931e;
    color: #fff;
}

.header_sideBar_topBar {
    background: #fff5e0;
    color: #000;
    filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, .3));
}

.navbar-toggler {
    background: transparent;
    color: #7c2004;
}

.header_sideBar .header_sideBar_body {
    /* background: #e6e6e6; */
    color: #fff;
}

.header_sideBar_menu .header_menu_content,
.header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {
    background: transparent;
    color: #666666;
    border-bottom: 1px solid #666666;
    margin: 0 .85rem;
}

.header_sideBar_menu .header_menu_content.active {
    background: transparent;
    color: #7c2004;
    border-bottom: 1px solid #666666;
}

.header_banking_info .header_banking_menu_list {
    color: #999;
}

.header_banking_info .header_banking_menu_list .active {
    color: #7c2004;
    background: transparent;
    border-radius: 5px;
}

.header_topBar_wrap .header_banking_info .header_balance {
    background: #f7931e;
    color: #fff;
    /* filter: drop-shadow(rgb(155, 155, 155) 0px 2px 2px); */
}

.header_banking_info .header_balance_icon {
    color: #000;
}

#promoClaimModal label {
    color: #7c2004;
}

#promoClaimModal .filterTab .filterTab_selection.active {
    background: #fff;
    color: #7c2004;
}

.footer {
    background: #7c2004;
    color: #fff;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
    /* border-top: 1px solid #7c2004; */
}

.footer_menu_wrap {
    border-bottom: 1px dashed #fff;
}

.footer_payment_method .footer_info_img li:first-of-type img {
    filter: brightness(0) invert(1);
}

.btnCancel {
    background: transparent;
    color: #000;
    border: 1px solid #7c2004;
    border-radius: 5px 5px 5px 5px;
}

.modal .confirm_button {
    background: linear-gradient(to top,#7c2004, #f7931e);
    color: #fff;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.modal .cancel_button {
    background: transparent;
    color: #000;
    border: 1px solid #7c2004;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.tab_pills_color.nav-pills .nav-link:hover {
    color: inherit;
}

.footer_help a:hover,
.header_banking_menu_list a:hover,
.header_menu_right .header_menu_page:hover,
.walletBar_selection:hover,
.banking_dropdown a li:hover,
.header_menu_page .header_text:hover,
.claim_dropdown li:hover {
    color: #7c2004;
}


.footer_help a:hover{
    color: #fff;
}

.header_menu_page .header_text:hover {
    color: #7c2004;
}

.banking_dropdown a li:hover,
.claim_dropdown li:hover {
    background: transparent;
    color: #7c2004;
}

.scrollToTopButton {
    background: linear-gradient(to top,#f59c00, #fbcc84);
    color: #7c2004;
    border-radius: 50%;
    padding: .375rem .75rem;
}

.swiper .swiper-pagination-bullet {
    background: #7c2004;
}

.swiper-pagination-bullet-active{
    background: #7c2004;

}

.alertMessage_content svg {
    color: #7c2004;
}

/* .form_required_input,
.register_error_msg,
.forgotPassword_error_msg,
.forgotUsername_error_msg,
.error_msg,
.form_required,
.displayQR_row label,
.input_error_msg {
    color: #fff;
} */

.login_form_reminder_title,
.register_form_reminder_title,
.forgotPassword_form_reminder_title,
.forgotUsername_form_reminder_title {
    color: red;
    font-weight: bold;
}

.forgot_content .forgot_content_username,
.forgot_content .forgot_content_password {
    color: red;
}

.btnCancelPromotion {
    background: transparent;
    color: #000;
}

.btnAfterVerify {
    background: linear-gradient(to top,#7c2004, #f7931e);
    color: #fff;
    border: 0;
}

.announcement_header_title span {
    margin-bottom: .25rem;
    color:#7c2004
}

.section_title {
    color: #000;
}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {
    background: #fff;
    color: #000;
    outline: 1px solid #f7931e;
    border-radius: 5px 5px 5px 5px;
}

#gameWalletModal .game_wallet_dropdown table tbody tr{
    color: #000;
    background: #e6e6e6;
  }

  #promoClaimModal .filterTab {
    background: #7c2004;
    color: #fff;
    border-radius: 25px;
  }
  
  #promoClaimModal .filterTab .filterTab_selection {
    border: 0;
    border-radius: 25px;
  }

  .section_details{
    color: #4d4d4d;
  }

  a.section_details:hover{
    color: #4d4d4d;
  }

  .card_feedback .feedback_rating_wrap .feedback_rating img{
    margin-right: .25rem;
  }

  .header_banking_info .header_banking_menu_list span.active{
    background: transparent;
  }
  /* .header_logo img {
    height: 4rem;
  }
  .header_d .header_logo img {
    height: 5rem;
  } */

  .header_sideBar.offcanvas{
    background: #f2f2f2;
    border-left: 0;
  }

  .gameWallet_accordion .accordion-button{
   background: #ccc;
   color: #000;
  }
  
  .dropdown_content{
    background: rgba(0,0,0,.95);
  }


  .header_info_section,.header_banking_info .header_balance_icon{
    color: #000;
  }

  .game_wallet_dropdown div.main_wallet{
    background:#7c2004;
  }

  .gameWallet_accordion .gameWallet_accordion_icon{
    background:#7c2004;
  }


  .game_wallet_dropdown table tbody tr{
    background: transparent;
    outline: 1px solid #f7931e;
    outline-offset: -1px;
  }


  .header_WalletIcon{
    height: 30px;
    width: 30px;
    margin-right: .75rem;
  }


  .header_d .header_topBar {
    background: url('https://storage.googleapis.com/ocwin-images.imbaweb.com/background/bg_header.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}


  .header_m .header_topBar {
    background: url('https://storage.googleapis.com/ocwin-images.imbaweb.com/background/bg_header_m.png');
    background-repeat: no-repeat;
    background-size: cover;
}